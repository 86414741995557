.lightsMap .mapButton {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    background-color: var(--color-primary);
    border: none;
    border-radius: 20px;
    padding: 10px 15px;
    margin: 5px;
    cursor: pointer;
    /*transition: all 0.3s ease-in-out; */
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    text-decoration: none;

    /* Show element centred inside */
    display: flex;
    justify-content: center; /* horizontal alignment */
    align-items: center; /* vertical alignment */
}

.lightsMap .mapButton:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
}

.lightsMap .searchBoxContainer {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    max-width: 700px;

}


.lightsMap tbody {
    background-color: rgb(248, 248, 248);;
    color: var(--color-text);
}

table tbody tr:hover {
    background-color: rgb(248, 248, 248);
    box-shadow: 0 0 10px rgba(220, 220, 220, 0.85);
    transition: 0.2s;
    transform: scale(1.01);
}

.lightsMap table {
    border-collapse: collapse;
    width: 70%;
}

.lightsMap .searchBoxRow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}



@media (max-width: 767px) {

    .lightsMap .searchBoxRow {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;
    }

    .lightsMap .searchBoxContainer {
        border-radius: 30px;
    }


    .lightsMap table {
        width: 100%;
    }


}